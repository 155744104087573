// extracted by mini-css-extract-plugin
export var alert = "StatusTag-module--alert--0ce36";
export var container = "StatusTag-module--container--afde6";
export var critical = "StatusTag-module--critical--e52be";
export var debug = "StatusTag-module--debug--804c4";
export var emergency = "StatusTag-module--emergency--ad366";
export var error = "StatusTag-module--error--7a46c";
export var info = "StatusTag-module--info--34481";
export var notice = "StatusTag-module--notice--be483";
export var success = "StatusTag-module--success--32826";
export var warning = "StatusTag-module--warning--9d77f";